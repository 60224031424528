/*GENERAL STYLING*/

h1, h2, h3, h4, a {
    font-family: semplicitapro, sans-serif;
}

h1, h2, h3, h4, p {
    margin: .25em;
}

h1 {
    font-size: 2.4em;
}

h2 {
    font-size: 1.8em;
}

h3 {
    font-size: 1.6em;
}

h4 {
    font-weight: 400;
}

p {
    font-family: ivyjournal, sans-serif;
    font-size: 1.1em;
}

a {
    all: unset;
}

label {
    font-family: semplicitapro, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    color: #A60303;
}

textarea {
    font-family: Arial, Helvetica, sans-serif;
    resize: none;
    width: 60%;
    height: 10em;
}

header {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: .5em;

    color: #A60303;
    fill: #A60303;

    font-size: 1.6em;
}

header svg {
    height: 2em;
}

#desktop-header, #mobile-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#desktop-header {
    display: none;
}

#open-menu-icon {
    cursor: pointer;
}

#close-menu-icon {
    cursor: pointer;
}

#mobile-menu {
    display: none;
    position: fixed;
    text-align: left;
    padding-left: 8%;
    padding-top: 1em;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #027373;
    color: white;
    font-family: semplicitapro, sans-serif;
    padding-bottom: 1em;
}

.nav-elements {
    display: flex;
    flex-direction: column;
}

.nav-elem {
    margin-bottom: 0.5em;
    cursor: pointer;
}

.nav-elem:hover {
    text-decoration: underline;
}

hr {
    border: 1px solid #A60303;
    width: 40%;
}

button {
    cursor: pointer;
    background-color: rgba(165, 3, 3, 0.5);
    color: white;
    border: none;
    padding: 1em;
    border-radius: 100em;
    font-family: semplicitapro, sans-serif;
    margin: 1em;
}

button:hover {
    background-color: rgba(165, 3, 3, 1);
}

/*HOME PAGE*/
#home-page {
    text-align: center;
    margin-bottom: 4em;
}

#short-bio {
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
    margin: auto;
    align-items: center;
}

#short-bio h4 {
    font-size: 1.6em;
    font-weight: 400;
}

#short-bio img {
    width: 40%;
    border: 0.6em solid #A60303;
    background-color: #A60303;
    border-radius: 100%;
}

#skills {
    width: 70%;
    margin: auto;
}

#home-page h3 {
    color: #027373;
    font-weight: 400;
}

/*TECHNICAL PAGE*/
#technical-page {
    text-align: center;
    margin-bottom: 4em;
}

#technical-page img {
    width: 60%;
}

#file-download{
    color: #A60303;
    cursor: pointer;
}

#file-download:hover {
    text-decoration: underline;
}

.project a {
    font-size: 1.3em;
    font-family: ivyjournal, sans-serif;
    color: #027373;
    cursor: pointer;
}

.project a:hover {
    text-decoration: underline;
}

.project-links {
    margin-bottom: 0.75em;
}

.project .first-link {
    border-right: 2px solid #A60303;
    padding-right: 0.5em;
    margin-right: 0.5em;
}

/*PHOTOGRAPHY PAGE*/
#photography-page header {
    margin-bottom: 0;
}

#main-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

#main-image img {
    max-width: 90%;
    max-height: 60vh;
}

#carousel-and-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    bottom: 1vh;
    left: 0;
}

.carousel-button {
    color: #A60303;
    font-size: 8vw;
}

#left-button {
    color: white;
}

#carousel {
    display: flex;
    flex-direction: row;
    width: 80%;
    overflow-x: auto;
    white-space: nowrap;
    overflow: hidden;
}

#selected {
    outline: 6px solid #A60303;
}

.carousel-image {
    width: 20%;
    margin: 2%;
}

#carousel .loading-div {
    width: 20%;
    height: 20%;
    margin: 2%;
}
/*CONTACT PAGE*/
#contact-page {
    text-align: center;
}

#contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media screen and (orientation: landscape) {
    /*GENERAL STYLING*/
    #desktop-header {
        display: flex;
    }

    #mobile-header {
        display: none;
    }

    .nav-elements {
        flex-direction: row;
    }

    .nav-elem {
        margin-left: 1.5em;
        font-family: semplicitapro, sans-serif;
    }

    .nav-elem:hover {
        color: #027373;
        text-decoration: none;
    }

    #selected-nav-elem {
        color: #027373;
    }

    /*HOME PAGE*/
    #short-bio {
        flex-direction: row;
        width: 50%;
        justify-content: space-evenly;
        text-align: left;
    }

    #short-bio img {
        max-width: 30%;
        max-height: 25vh;
        width: auto;
        height: auto;
    }
    
    #skills {
        width: 90%;
    }

    #skills-sections {
        display: flex;
        justify-content: center;
    }

    .skill-section {
        width: 40%;
    }

    #skills-section-hr {
        display: none;
    }

    #left-section {
        padding-right: 2em;
    }

    #right-section {
        padding-left: 2em;
        border-left: 2px solid #A60303;
    }

    /*TECHNICAL PAGE*/
    #projects {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 80%;
        margin: auto;
    }

    .project {
        width: 50%;
    }

    #technical-page img {
        width: 80%;
    }

    /*PHOTOGRAPHY PAGE*/
    #main-image {
        margin-top: 2vh;
    }

    #main-image img {
        max-height: 70vh;
    }

    #carousel-and-buttons {
        bottom: 0;
        width: 90%;
        left: 5%;
    }

    #carousel {
        width: 90%;
    }

    .carousel-button {
        font-size: 4vw;
    }

    .carousel-image {
        width: 6%;
        height: auto;
        margin: 1%;
    }
}
